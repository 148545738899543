import Ajv from 'ajv';
import { injectable } from 'inversify';
import { appConfig } from 'src/lib/config/appConfig';
import type { ConfigService, LaunchDarklyConfig } from 'src/services/ConfigService';
import { OAuth2ConfigSchema } from 'src/types/schemas/OAuthConfigSchema';
import type { OAuth2Config } from 'src/types/schemas/OAuthConfigSchema';

@injectable()
export class ConfigServiceImpl implements ConfigService {
    #appConfig = appConfig;

    #validateOverride = new Ajv({ removeAdditional: 'all' }).compile(OAuth2ConfigSchema);

    get launchDarklyConfig(): LaunchDarklyConfig {
        return {
            clientSideId: this.#appConfig.launchDarklyClientSideId,
        };
    }

    get oAuthConfig(): OAuth2Config {
        if (APP_ELECTRON) {
            return this.#appConfig.oAuth2Config;
        }

        const override = localStorage.getItem('configService.oAuthConfig.override');
        if (override) {
            const value = JSON.parse(override);
            const valid = this.#validateOverride(JSON.parse(override));
            if (!valid) {
                // eslint-disable-next-line no-console
                console.error('Invalid override value for oAuthConfig', this.#validateOverride.errors);
            }

            return value;
        }

        return this.#appConfig.oAuth2Config;
    }

    get environmentName() {
        return this.#appConfig.environmentName;
    }

    get commit() {
        return this.#appConfig.commit;
    }

    get documentTitle() {
        return this.#appConfig.documentTitle;
    }
}
