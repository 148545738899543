import { Type } from '@sinclair/typebox';
import type { Static } from '@sinclair/typebox';

export const KeycloakConfigSchema = Type.Object({
    applicationID: Type.String(),
    callbackURL: Type.String(),
    keycloakURL: Type.String(),
    loggedOutURL: Type.String(),
    logoutURL: Type.String(),
    tenantID: Type.String(),
    tokenRevocationURL: Type.String(),
    type: Type.Literal('keycloak'),
    userInfoURL: Type.String(),
});

export const OAuth2ConfigSchema = KeycloakConfigSchema;

export type KeycloakConfig = Static<typeof KeycloakConfigSchema>;
export type OAuth2Config = Static<typeof OAuth2ConfigSchema>;
